import * as React from 'react'
import { FunctionComponent, useState, useEffect } from 'react'

interface EditAlphaTabProps {
  isSelected: boolean
  onClick: () => void
  label: string
  icon: string
  hoverIcon: string
}

const EditAlphaTab: FunctionComponent<EditAlphaTabProps> = ({
  isSelected,
  onClick,
  label,
  icon,
  hoverIcon
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    setIsFocused(isSelected || isHovered)
  }, [isHovered, isSelected])

  return (
    <a
      className={`flex items-center flex-col gap-4 px-0 w-full py-4 transition-colors cursor-pointer box-border border-0 border-b-4 border-solid ${
        isFocused ? 'border-alpha-red' : 'border-transparent'
      }`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img
        className='w-10 lg:w-12 aspect-square'
        src={`../../../images/icons/tabs/${isFocused ? hoverIcon : icon}`}
        alt={label}
      />
      <span
        className={`font-medium leading-tight text-sx lg:text-base text-center text-wrap lg:text-nowrap px-3 md:px-4 ${
          isFocused ? 'text-black' : 'text-alpha-grey-dark'
        }`}
      >
        {label}
      </span>
    </a>
  )
}

export default EditAlphaTab
