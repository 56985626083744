import * as React from 'react'
import { FunctionComponent, useContext } from 'react'
import {
  IoCalendarOutline as DateIcon,
  IoTimeOutline as TimeIcon,
  IoLocationOutline as AddressIcon
} from 'react-icons/io5'
import { MdEdit as EditIcon } from 'react-icons/md'
import { HiDuplicate as DuplicateIcon } from 'react-icons/hi'
import { Button } from '@alpha-international/alpha-ui'
import ProductCardDetail from './product-card-detail'
import ProductCardPill from './product-card-pill'
import { ComponentWrapperContext } from '../context'

export interface ProductCardProps {
  title: string
  date: number
  address: string
  showPrivateTag?: boolean
  showAdminTag?: boolean
  role?: 'teamMember' | 'administrator'
  hostPage: 'editAlpha' | 'preview'
  showDuplicateButton?: boolean
  image: string
  handleClick: () => {}
  isEditButtonVisible: boolean
}

const ProductCard: FunctionComponent<ProductCardProps> = ({
  title,
  date,
  address,
  showPrivateTag,
  showAdminTag,
  role,
  hostPage = 'editAlpha',
  showDuplicateButton,
  image,
  handleClick,
  isEditButtonVisible
}) => {
  const { locale } = useContext(ComponentWrapperContext)
  const formattedDate = new Date(date).toLocaleDateString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    weekday: 'long'
  })

  const formattedTime = new Date(date).toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: '2-digit'
  })

  const buttonLabels = {
    editDetails: $localize`:common.button.label|Preview Alpha - Edit Details@@editDetails:Edit details`,
    accessSeriesMaterials: $localize`:common.button.label|Preview Alpha - Access Series Materials@@accessSeriesMaterials:Access Series Materials`,
    duplicate: $localize`:common.button|Duplicate Button@@duplicateButton:Duplicate`
  }

  return (
    <div className='flex px-4 md:px-8 py-6 gap-5 rounded-lg bg-alpha-white border border-alpha-oatmeal-10 border-solid'>
      <div className='flex flex-col gap-4 flex-1 pt-0 lg:pt-4'>
        <div className='flex flex-col xl:flex-row gap-4'>
          {title ? (
            <h3 className='mt-0 mb-[3px] leading-none flex-1 text-2xl sm:text-3xl'>
              {title}
            </h3>
          ) : null}
          <div className='flex flex-wrap gap-4 empty:hidden'>
            <ProductCardPill variant={showPrivateTag ? 'private' : 'public'} />
            {showAdminTag && role ? (
              <ProductCardPill variant='role' role={role} />
            ) : null}
          </div>
        </div>
        <div className='flex flex-col gap-[3px]'>
          {date ? (
            <ProductCardDetail icon={DateIcon} text={formattedDate} />
          ) : null}
          {date ? (
            <ProductCardDetail icon={TimeIcon} text={formattedTime} />
          ) : null}
          {address ? (
            <ProductCardDetail icon={AddressIcon} text={address} />
          ) : null}
        </div>
        <div className='flex justify-between content-center font-body'>
          {isEditButtonVisible ? (
            <Button
              onClick={() => handleClick()}
              className='flex gap-2 justify-center rounded-[8px] items-center font-medium text-base border-0 border-none cursor-pointer hover:bg-alpha-red-110 transition-colors w-full sm:w-auto'
            >
              {hostPage === 'editAlpha' ? (
                <>
                  <EditIcon size={'1rem'} color='white' />
                  {buttonLabels.editDetails}
                </>
              ) : null}
              {hostPage === 'preview' ? (
                <>
                  {buttonLabels.accessSeriesMaterials}
                  <EditIcon size={'1rem'} color='white' />
                </>
              ) : null}
            </Button>
          ) : null}
          {showDuplicateButton ? (
            <Button
              onClick={handleClick}
              size='small'
              className='flex gap-2 justify-center text-sm font-medium rounded-[8px] items-center !text-mya-link bg-transparent hover:bg-slate-100 border-none'
            >
              <DuplicateIcon size={'1rem'} />
              {buttonLabels.duplicate}
            </Button>
          ) : null}
        </div>
      </div>
      <div
        className='hidden lg:block h-40 lg:h-52	xl:h-60 aspect-square bg-cover bg-center'
        style={{
          backgroundImage: image ? `url(${image})` : '',
          backgroundColor: '#D6D2D0'
        }}
      ></div>
    </div>
  )
}

export default ProductCard
