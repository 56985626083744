import * as React from 'react'
import { FunctionComponent } from 'react'

import { IoChevronDown as ChevronDownIcon } from 'react-icons/io5'
import DropdownDialog from '@builder/react-ui/dropdown-dialog'

type PromoteTabLanguagesDropdownProps = {
  options: any[]
  setSelectedOption: (input: any) => void
  selectedOption: {
    label: string
    name: string
  }
}

const PromoteTabLanguagesDropdown: FunctionComponent<
  PromoteTabLanguagesDropdownProps
> = ({ options, setSelectedOption, selectedOption }) => {
  if (!options) {
    return null
  }
  options = options.map(({ label, code }) => ({ label, name: code }))
  const button: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
    <div
      role='button'
      onClick={onClick}
      className='bg-alpha-oatmeal hover:bg-alpha-oatmeal-10 flex border-none gap-2 justify-between pl-8 pr-6 py-3 text-left items-center rounded-full font-body font-bold text-base text-alpha-black cursor-pointer transition-colors'
    >
      {selectedOption.label}
      <ChevronDownIcon size={24} />
    </div>
  )

  return options && options.length > 1 ? (
    <DropdownDialog
      button={button}
      options={options}
      setOption={setSelectedOption}
    />
  ) : null
}

export { PromoteTabLanguagesDropdown }
