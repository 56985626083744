import * as React from 'react'
import { FunctionComponent } from 'react'
import Carousel, { CarouselProps } from 'react-multi-carousel'
import hash from 'object-hash'
import { CarouselArrowButtons } from './carouselArrowButtons'

type ReactMultiCarouselProps = Omit<CarouselProps, 'children' | 'responsive'> &
  Partial<Pick<CarouselProps, 'children' | 'responsive'>> & {
    slides: any[]
    slideComponent: FunctionComponent
    children?: any
  }

const ReactMultiCarousel: FunctionComponent<ReactMultiCarouselProps> = ({
  slides,
  slideComponent,
  ...carouselProps
}) => {
  const SlideComponent = slideComponent
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  }

  return (
    <Carousel
      responsive={responsive}
      customButtonGroup={<CarouselArrowButtons />}
      {...carouselProps}
    >
      {slides.map((data) => (
        <SlideComponent key={hash(data)} {...data} />
      ))}
    </Carousel>
  )
}

export default ReactMultiCarousel
