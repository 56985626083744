import * as React from 'react'
import { FunctionComponent } from 'react'
import { Loader } from '@alpha-international/alpha-ui'
import { Resource } from '@builder/resources'

import { ResourceList } from '@react-ui'
import { ResourceClickHandlerContext } from '@react-ui/context'

const PRODUCT_RESOURCES_NAME = 'talks'
const OTHER_RESOURCES_NAME = 'toolbox'

interface Bucket {
  items: Resource[]
  label: string
  name: string
}

interface Materials {
  buckets: Bucket[]
  description: string
  label: string
  name: string
}

export interface SeriesMaterialsTabProps {
  materialsResponse: Materials[]
  seriesName: string
  resourceClickHandler: (resource) => {}
}

const SeriesMaterialsTab: FunctionComponent<SeriesMaterialsTabProps> = (
  props
) => {
  const { materialsResponse, seriesName, resourceClickHandler } = props
  if (!materialsResponse || !seriesName || !resourceClickHandler) {
    return (
      <div className='w-full flex justify-center align-center lg:py-80 md:py-60 sm:py-40 py-20'>
        <Loader />
      </div>
    )
  }

  const featuredResources = materialsResponse
    .find((bucket) => PRODUCT_RESOURCES_NAME === bucket.name)
    ?.buckets?.at(0)?.items

  const otherResourceBuckets = materialsResponse
    .find((bucket) => OTHER_RESOURCES_NAME === bucket.name)
    ?.buckets?.map(({ label, items }) => ({
      title: label,
      resources: items
    }))

  return (
    <ResourceClickHandlerContext.Provider value={{ resourceClickHandler }}>
      <div className='flex flex-col gap-8'>
        <ResourceList
          title={seriesName}
          resources={featuredResources}
          isFeatured
        />
        {otherResourceBuckets?.length
          ? otherResourceBuckets.map(({ title, resources }) => (
              <ResourceList key={title} title={title} resources={resources} />
            ))
          : null}
      </div>
    </ResourceClickHandlerContext.Provider>
  )
}

export default SeriesMaterialsTab
