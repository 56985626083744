import * as React from 'react'
import { FunctionComponent, useContext } from 'react'
import {
  IoVideocam as VideoIcon,
  IoDocumentText as DocumentIcon
} from 'react-icons/io5'
import { FaLink as LinkIcon } from 'react-icons/fa6'
import { IoCaretForward as PlayIcon } from 'react-icons/io5'

import ResourceCardOverlayButton from './resource-card-overlay-button'
import { formatResourceForResourceCard } from './resource-card.utils'
import ResourceCardOverlay from './resource-card-overlay'
import { VideoPlayerModalContext } from '../context'

const ResourceCard: FunctionComponent<any> = (props) => {
  const {
    type,
    image,
    title,
    description,
    showNewTag,
    duration,
    resource,
    id
  } = formatResourceForResourceCard(props)
  const videoPlayerModalContext = useContext(VideoPlayerModalContext)
  const activeResourceId = videoPlayerModalContext?.activeResourceId || null

  const Icon =
    type === 'link' ? LinkIcon : type === 'document' ? DocumentIcon : VideoIcon
  return (
    <div className='p-2 box-border'>
      <div
        className='overflow-hidden rounded-2xl aspect-video bg-cover relative flex items-center justify-center'
        style={{
          backgroundImage: image ? `url(${image})` : '',
          backgroundColor: '#D6D2D0'
        }}
      >
        {!image ? (
          <div className='bg-alpha-white rounded-full text-alpha-oatmeal-10 absolute aspect-square flex items-center justify-center h-1/2'>
            <Icon size='50%' />
          </div>
        ) : null}
        {type === 'video' ? (
          <ResourceCardOverlay
            isAlwaysOnTop
            isVisible={activeResourceId && activeResourceId === id}
          >
            <p className='text-alpha-white font-semibold flex items-center'>
              <PlayIcon size='1.25rem' />
              {$localize`:common.words|Currently Playing@@currentlyPlaying:Currently playing`}
            </p>
          </ResourceCardOverlay>
        ) : null}

        {type !== 'video' || !activeResourceId || activeResourceId !== id ? (
          <ResourceCardOverlay>
            <ResourceCardOverlayButton type={type} resource={resource} />
          </ResourceCardOverlay>
        ) : null}

        {showNewTag ? (
          <div className='bg-alpha-white uppercase rounded-full absolute top-3 right-3 text-alpha-black py-[2px] px-5 text-sm'>
            {$localize`:common.words|New@@commonWordNew:New`}
          </div>
        ) : null}
        {duration ? (
          <div className='bg-alpha-black uppercase rounded-full absolute bottom-3 right-3 text-alpha-white py-[2px] px-5 text-sm'>
            {duration}
          </div>
        ) : null}
      </div>
      {title ? (
        <p className='font-display font-medium mt-3 mb-[3px] text-base'>
          {title}
        </p>
      ) : null}
      {description ? (
        <p className='font-body text-sm m-0 truncate'>{description}</p>
      ) : null}
    </div>
  )
}

export default ResourceCard
