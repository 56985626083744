import * as React from 'react'
import {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
  useContext
} from 'react'
import VideoPlayerModalDownloadButton from './video-player-modal-download-button'

import { BRIGHTCOVE, VIMEO } from '../video-player/video-player'
import VideoPlayerModalDownloadHelperText from './video-player-modal-download-helper-text'
import { createShareUrlById } from './video-player-utils'
import VideoPlayerModalDownloadOptionsDropdown from './video-player-modal-download-options-dropdown'
import {
  VideoPlayerModalContext,
  VideoPlayerModalShareUrlContext
} from '../context'

const VideoPlayerModalDownloadOptionsWrapper: FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  return (
    <div className='flex flex-col flex-1 gap-6 py-6 px-4 bg-alpha-oatmeal'>
      {children}
    </div>
  )
}

const VideoPlayerModalDownloadOptions: FunctionComponent = () => {
  const { options, provider } = useContext(VideoPlayerModalContext)

  const [languageOptions, setLanguageOptions] = useState(
    options.languageOptions
  )
  const { setShareUrl } = useContext(VideoPlayerModalShareUrlContext)

  const [language, setLanguage] = useState<{
    audio: string | null
    subtitle: string | null
  }>({
    audio: null,
    subtitle: null
  })

  const [renditionLabel, setRenditionLabel] = useState<string>('')
  const [renditionOptions, setRenditionOptions] = useState<any>()
  const [rendition, setRendition] = useState<string | null>('')
  const [download, setDownload] = useState<{ id: string; url: string }>()
  const [languageDropdownOptions, setLanguageDropdownOptions] =
    useState<any[]>()
  const [renditionDropdownOptions, setRenditionDropdownOptions] =
    useState<any[]>()

  useEffect(() => {
    if (options?.languageOptions) {
      setLanguageOptions(options?.languageOptions)
    }
  }, [options])

  useEffect(() => {
    if (languageOptions?.length) {
      setLanguageDropdownOptions(
        languageOptions.map((option) => ({
          value: JSON.stringify(option.value),
          label: option.label
        }))
      )

      const firstOption = languageOptions[0].value
      setLanguage({
        audio: firstOption.audio,
        subtitle: firstOption.subtitle || ''
      })
    }
  }, [languageOptions])

  useEffect(() => {
    const { audio, subtitle } = language
    if (
      audio &&
      subtitle &&
      options.audioLanguages[audio].subtitles[subtitle]
    ) {
      setRenditionOptions(options.audioLanguages[audio].subtitles[subtitle])
      return
    }
    if (audio) {
      setRenditionOptions(options.audioLanguages[audio].videoData)
      return
    }
  }, [language])

  useEffect(() => {
    if (!renditionOptions) return

    setRenditionDropdownOptions(
      renditionOptions.list.map((renditionKey) => ({
        value: renditionKey,
        label: renditionOptions.renditions[renditionKey].label
      }))
    )

    if (renditionOptions.list.includes(rendition)) {
      setDownload({
        id: renditionOptions.id,
        url: renditionOptions.renditions[rendition].isExpired
          ? ''
          : renditionOptions.renditions[rendition].link
      })
    } else {
      setRendition(renditionOptions.list[0])
    }
    if (provider === VIMEO && renditionOptions.videoId) {
      setShareUrl(createShareUrlById(renditionOptions.videoId))
    }
  }, [renditionOptions])

  useEffect(() => {
    if (!rendition) return

    setRenditionLabel(renditionOptions.renditions[rendition].label)
    setDownload({
      id: renditionOptions.id,
      url: renditionOptions.renditions[rendition].isExpired
        ? ''
        : renditionOptions.renditions[rendition].link
    })
  }, [rendition])

  const handleLanguage = (input) => {
    const value = JSON.parse(input)
    setLanguage(value)
  }

  const handleRendition = (input) => {
    setRendition(input)
  }

  if (!languageOptions || !renditionOptions) {
    return <p>Loading..</p>
  }

  if (languageOptions.length < 2 && renditionOptions.list.length < 2) {
    return (
      <VideoPlayerModalDownloadOptionsWrapper>
        {provider === BRIGHTCOVE ? (
          <VideoPlayerModalDownloadHelperText />
        ) : null}
        <VideoPlayerModalDownloadButton
          download={download}
          rendition={rendition}
        />
      </VideoPlayerModalDownloadOptionsWrapper>
    )
  }

  return (
    <VideoPlayerModalDownloadOptionsWrapper>
      <div className='flex flex-col gap-3 md:min-w-80'>
        <label className='font-display font-medium text-sm'>
          {$localize`:videoDownloadOptions.label|Video Download Options label@@VideoDownloadOptionsLabel:Video Download Options`}
        </label>

        {languageDropdownOptions?.length ? (
          <VideoPlayerModalDownloadOptionsDropdown
            isLanguageSelector
            value={JSON.stringify(language)}
            clickHandler={handleLanguage}
            options={languageDropdownOptions}
          />
        ) : null}

        {renditionDropdownOptions?.length ? (
          <VideoPlayerModalDownloadOptionsDropdown
            value={renditionLabel}
            clickHandler={handleRendition}
            options={renditionDropdownOptions}
          />
        ) : null}

        {provider === BRIGHTCOVE ? (
          <VideoPlayerModalDownloadHelperText />
        ) : null}
      </div>
      <VideoPlayerModalDownloadButton
        download={download}
        rendition={rendition}
      />
    </VideoPlayerModalDownloadOptionsWrapper>
  )
}

export default VideoPlayerModalDownloadOptions
