import * as React from 'react'
import { FunctionComponent, useState, useContext, useEffect } from 'react'
import { IoCaretForward as PlayIcon, IoLink as LinkIcon } from 'react-icons/io5'
import { HiDownload as DownloadIcon } from 'react-icons/hi'

import Notification from '../notification'
import { ErrorWithDetailedMessage } from '../notification/notification'
import { ResourceClickHandlerContext } from '../context'

export interface ResourceCardOverlayButtonProps {
  type: 'link' | 'document' | 'video'
  resource: any
}

const ResourceCardOverlayButton: FunctionComponent<
  ResourceCardOverlayButtonProps
> = ({ ...props }) => {
  const { type, resource } = props
  const { resourceClickHandler, videoClickHandler } = useContext(
    ResourceClickHandlerContext
  )
  const [error, setError] = useState<ErrorWithDetailedMessage>(null)
  const [fileExtension, setFileExtension] = useState<string>(null)

  useEffect(() => {
    if (type === 'document' && resource?.attachedDocument?.url) {
      try {
        const ext = resource.attachedDocument.url.split('.').pop()
        setFileExtension(ext)
      } catch (error) {
        console.log(
          `Attached document uses unrecognized format`,
          { resource },
          { error }
        )
      }
    }
  }, [])

  const clickHandler = () => {
    try {
      if (type == 'video' && videoClickHandler) {
        videoClickHandler(resource)
      } else {
        resourceClickHandler(resource)
      }
    } catch (error) {
      const { message } = error
      error.detailedMessage = message

      error.message =
        `There was a issue with this resource` +
        `${resource?.attachedDocument?.title ? `: ${resource.attachedDocument.title}` : ''}`

      setError(error)
    }
  }

  const Icon =
    type === 'link' ? LinkIcon : type === 'document' ? DownloadIcon : PlayIcon
  const SizedIcon = () => <Icon size='1.5rem' />

  return (
    <>
      {error ? (
        <Notification error={error} onClose={() => setError(null)} />
      ) : null}

      <div
        role='button'
        onClick={clickHandler}
        className='w-full h-full flex items-center justify-center p-2 rounded-lg gap-1 text-alpha-white cursor-pointer border-0 bg-transparent'
      >
        <SizedIcon />
        <span>
          {type === 'link'
            ? $localize`:videoplayer.link|Navigate to Link@@navigateToLink:Navigate to Link`
            : null}
          {type === 'document'
            ? ['pdf', 'png', 'jpg'].includes(fileExtension)
              ? $localize`:videoplayer.link|Preview and Download@@previewAndDownload:Preview and Download`
              : $localize`:videoplayer.link|Download File@@Download File:Download File`
            : null}
          {type === 'video'
            ? $localize`:videoplayer.link|Watch and Download@@watchAndDownload:Watch and Download`
            : null}
        </span>
      </div>
    </>
  )
}

export default ResourceCardOverlayButton
