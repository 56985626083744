import * as React from 'react'
import { FunctionComponent, useEffect } from 'react'
import { useState } from 'react'
import Carousel from 'react-multi-carousel'
import { useMediaQuery } from 'react-responsive'
import EditAlphaTab from './edit-alpha-tab'

interface EditAlphaTabsProps {
  navigateTab: (route) => {}
  isMyTeamTabVisible: boolean
}

const EditAlphaTabs: FunctionComponent<EditAlphaTabsProps> = ({
  navigateTab,
  isMyTeamTabVisible
}) => {
  const tabs = [
    {
      label: $localize`:series|Series Materials label@@seriesMaterialsLabel:Series Materials`,
      route: 'materials',
      icon: 'series_materials.svg',
      hover_icon: 'series_materials_hover.svg'
    },
    {
      label: $localize`:series|What's Next label@@whatsNextLabel:What's Next`,
      route: 'upcoming',
      icon: 'whats_next.svg',
      hover_icon: 'whats_next_hover.svg'
    },
    {
      label: $localize`:common.words|Common Word - Schedule@@teamTabSchedule:Schedule`,
      route: 'plan',
      icon: 'schedule.svg',
      hover_icon: 'schedule_hover.svg'
    },
    {
      label: $localize`:common.buttons|Promote@@buttonPromote:Promote`,
      route: 'promote',
      icon: 'promote.svg',
      hover_icon: 'promote_hover.svg'
    },
    ...(isMyTeamTabVisible
      ? [
          {
            label: $localize`:series|My Team label@@labelMyTeam:My Team`,
            route: 'team',
            icon: 'my_team.svg',
            hover_icon: 'my_team_hover.svg'
          }
        ]
      : [])
  ]

  const isMobile = useMediaQuery({ maxWidth: '560px' })
  const [selectedTab, setSelectedTab] = useState<number>(0)
  // Effect to set the selected tab based on the current URL
  useEffect(() => {
    const currentPath = window.location.pathname
    const activeTabIndex = tabs.findIndex((tab) =>
      currentPath.includes(tab.route)
    )
    if (activeTabIndex !== -1) {
      setSelectedTab(activeTabIndex)
    }
  }, [])

  const handleTabClick = (index: number, route: string) => {
    setSelectedTab(index)
    navigateTab(route)
  }
  return isMobile ? (
    <div className='font-display rounded-t-lg border-b border-solid	pt-2 border-alpha-oatmeal-10'>
      <Carousel
        responsive={{
          all: {
            breakpoint: { max: 3000, min: 400 },
            items: 3,
            partialVisibilityGutter: 25
          },
          xs: {
            breakpoint: { max: 400, min: 0 },
            items: 2,
            partialVisibilityGutter: 20
          }
        }}
        arrows={false}
        swipeable
        draggable
        partialVisible
        focusOnSelect
        itemClass={'flex'}
      >
        {tabs.map((tab, index) => (
          <EditAlphaTab
            isSelected={selectedTab === index}
            onClick={() => handleTabClick(index, tab.route)}
            label={tab.label}
            icon={tab.icon}
            hoverIcon={tab.hover_icon}
            key={index}
          />
        ))}
      </Carousel>
    </div>
  ) : (
    <div className='flex justify-center font-display rounded-t-lg border-b border-solid	pt-2 border-alpha-oatmeal-10'>
      {tabs.map((tab, index) => (
        <EditAlphaTab
          isSelected={selectedTab === index}
          onClick={() => handleTabClick(index, tab.route)}
          label={tab.label}
          icon={tab.icon}
          hoverIcon={tab.hover_icon}
          key={index}
        />
      ))}
    </div>
  )
}

export default EditAlphaTabs
