import * as React from 'react'
import { FunctionComponent } from 'react'
import ErrorBoundary from './error-boundary'
import { ComponentWrapperContext, IComponentWrapperContext } from './context'

type ComponentWrapperProps = {
  componentProps: any
  component?: FunctionComponent | undefined
  contextValue: IComponentWrapperContext
}

const ComponentWrapper: FunctionComponent<ComponentWrapperProps> = (
  props: ComponentWrapperProps
) => {
  const { componentProps, component, contextValue } = props

  const Component = component
  return (
    <ComponentWrapperContext.Provider value={contextValue}>
      <ErrorBoundary>
        <Component {...componentProps} />
      </ErrorBoundary>
    </ComponentWrapperContext.Provider>
  )
}

export default ComponentWrapper
