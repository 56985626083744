import * as React from 'react'
import { FunctionComponent, useState, useEffect } from 'react'
import { Resource } from '@builder/resources'
import { ResourceList, ResourceCard, LoadingSection } from '@react-ui'
import { ResourceClickHandlerContext } from '@react-ui/context'
import PromoteTabCategoryDropdown from './promote-tab-category-dropdown'
import PromoteTabLanguagesDropdown from './promote-tab-languages-dropdown'
import { Observable } from 'rxjs'

interface Bucket {
  items: Resource[]
  label: string
  name: string
}

interface Materials {
  buckets: Bucket[]
  description: string
  label: string
  name: string
}

export interface PromoteTabProps {
  promotionalMaterialsResponse: any
  seriesName: string
  resourceClickHandler: (resource) => void
  getPromotionalMaterials: (language) => Observable<any>
  languages: any[]
  defaultLanguage: string
}

const PromoteTab: FunctionComponent<PromoteTabProps> = (props) => {
  const {
    promotionalMaterialsResponse,
    seriesName,
    resourceClickHandler,
    getPromotionalMaterials,
    languages,
    defaultLanguage
  } = props
  const LATEST = 'latest'

  if (!promotionalMaterialsResponse || !seriesName || !resourceClickHandler) {
    return null
  }

  const [defaultAllCategories, defaultLatestResources]: [
    Materials[],
    Resource[]
  ] = promotionalMaterialsResponse

  const optionLatest = {
    label: $localize`:promoteTab.label|Promote Tab Dropdown Label - Latest Promo Resources label@@LatestPromoResourcesLabel:Latest Promo Resources`,
    name: LATEST
  }

  const [selectedCategoryOption, setSelectedCategoryOption] =
    useState(optionLatest)

  const initialSelectedLanguageOption = languages.find(
    (language) => language.code === defaultLanguage
  )

  const englishLanguageOption = languages.find(
    (language) => language.code === 'en'
  )

  const [selectedLanguageOption, setSelectedLanguageOption] = useState(
    initialSelectedLanguageOption || englishLanguageOption || languages[0]
  )

  const [allCategories, setAllCategories] =
    useState<Materials[]>(defaultAllCategories)
  const [allLatestResources, setAllLatestResources] = useState<Resource[]>(
    defaultLatestResources
  )
  const [latestResources, setLatestResources] = useState<Resource[]>([])
  const [selectedCategory, setSelectedCategory] = useState<Materials>()
  const [options, setOptions] = useState([])
  const [languagesOptions, setLanguageOptions] = useState([])
  const [categories, setCategories] = useState<Materials[]>([])
  const [categoryMap, setCategoryMap] = useState<{ [key: string]: Materials }>(
    {}
  )
  const [isEmptyPage, setIsEmptyPage] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setLanguageOptions(languages || [])
  }, [languages])

  useEffect(() => {
    setCategories(
      allCategories?.length
        ? allCategories.filter(({ buckets }) => buckets.length)
        : []
    )
  }, [allCategories])

  useEffect(() => {
    setLatestResources([...allLatestResources].slice(0, 9))
  }, [allLatestResources])

  useEffect(() => {
    setOptions([
      optionLatest,
      ...categories.map(({ label, name }) => ({
        label,
        name
      }))
    ])
  }, [categories])

  useEffect(() => {
    setIsEmptyPage(!Boolean(categories.length) && !latestResources?.length)
  }, [categories, latestResources])

  useEffect(() => {
    const resourceCategoryMap = categories?.length
      ? categories.reduce((acc, curr) => {
          return { ...acc, [curr.name]: curr }
        }, {})
      : {}

    setCategoryMap(resourceCategoryMap)
  }, [options])

  useEffect(() => {
    setIsLoading(true)

    getPromotionalMaterials(selectedLanguageOption.name).subscribe({
      next: ([categories, latest]) => {
        setAllLatestResources(latest)
        setAllCategories(categories)
        setSelectedCategoryOption(optionLatest)
      },
      error: (error) => {
        console.log('invalid response for getPromotionalMaterials', { error })
        setIsEmptyPage(true)
        setIsLoading(false)
      },
      complete: () => {
        setIsLoading(false)
      }
    })
  }, [selectedLanguageOption])

  useEffect(() => {
    setSelectedCategory(
      selectedCategoryOption.name !== LATEST
        ? categoryMap[selectedCategoryOption.name]
        : null
    )
  }, [selectedCategoryOption])

  if (isLoading) {
    return <LoadingSection />
  }

  return (
    <ResourceClickHandlerContext.Provider value={{ resourceClickHandler }}>
      <div className='flex flex-col gap-8'>
        <div className='flex w-full gap-6 md:gap-8 xl:gap-32 justify-between flex-col sm:flex-row items-stretch sm:items-start md:items-center'>
          <PromoteTabCategoryDropdown
            options={options}
            setSelectedOption={setSelectedCategoryOption}
            selectedOption={selectedCategoryOption}
          ></PromoteTabCategoryDropdown>
          <PromoteTabLanguagesDropdown
            options={languagesOptions}
            setSelectedOption={setSelectedLanguageOption}
            selectedOption={selectedLanguageOption}
          ></PromoteTabLanguagesDropdown>
        </div>
        <>
          {isEmptyPage ? (
            <div className='flex flex-col gap-1 items-center'>
              <h5 className='font-display text-lg my-0'>{$localize`:common.words|Sorry@@sorry:Sorry!`}</h5>
              <p className='text-base my-0'>{$localize`:alpha.promote.search|No Material Message@@messageNoMaterial:There is no promotional material available in this language.`}</p>
            </div>
          ) : (
            <>
              {latestResources && selectedCategoryOption?.name === LATEST ? (
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                  {latestResources.map((resource) => {
                    return <ResourceCard key={resource.id} {...resource} />
                  })}
                </div>
              ) : null}

              {selectedCategory?.buckets.map(({ label, name, items }) => (
                <ResourceList key={name} title={label} resources={items} />
              ))}
            </>
          )}
        </>
      </div>
    </ResourceClickHandlerContext.Provider>
  )
}

export default PromoteTab
