export interface ResourceCardProps {
  title: string
  description?: string
  image: string
  showNewTag?: boolean
  duration?: string
  type?: 'link' | 'document' | 'video'
  attachedDocument?: any
  resource: any
  id: number
}

const formatResourceForResourceCard = (props): ResourceCardProps => {
  const { type, thumbnail, title, attachedDocument, description, id } = props

  const image = thumbnail?.url ? thumbnail.url : null

  return {
    type,
    image,
    title,
    description,
    attachedDocument,
    resource: props,
    showNewTag: false,
    duration: null,
    id
  }
}

export { formatResourceForResourceCard }
