import * as React from 'react'
import { useState, useEffect } from 'react'
import VideoPlayerModalComponent from './video-player-modal-component'
import { processVideoProps } from './video-player-utils'
import {
  ResourceClickHandlerContext,
  VideoPlayerModalContext,
  VideoPlayerModalShareUrlContext
} from '../context'

const VideoPlayerModal = ({ resourceClickHandler, ...otherProps }) => {
  let processedProps
  try {
    processedProps = processVideoProps(otherProps)
  } catch (error) {
    throw error
  }
  const { prepareVideoPlayerResource } = processedProps

  const parentVideoProps = processedProps

  processedProps.parentVideoTitle = parentVideoProps.title

  const [shareUrl, setShareUrl] = useState('')
  const [videoProps, setVideoProps] = useState(processedProps)

  const videoClickHandler = async (resource) => {
    const updatedResource = await prepareVideoPlayerResource(resource)

    const { url, title, description, video, id } = updatedResource

    const { provider } = video

    const propsObj = {
      video,
      title,
      description,
      provider,
      url
    }

    setVideoProps({
      ...processVideoProps(propsObj),
      parentVideoTitle: parentVideoProps.title,
      activeResourceId: resource.id,
      relatedDocuments: parentVideoProps.relatedDocuments,
      backToParentVideo:
        parentVideoProps.id !== resource.id
          ? () => {
              setVideoProps({
                ...parentVideoProps,
                activeResourceId: null
              })
            }
          : null
    })
  }

  useEffect(() => {
    setShareUrl(videoProps.shareUrl)
  }, [videoProps])

  return (
    <ResourceClickHandlerContext.Provider
      value={{
        resourceClickHandler,
        videoClickHandler
      }}
    >
      <VideoPlayerModalContext.Provider value={videoProps}>
        <VideoPlayerModalShareUrlContext.Provider
          value={{
            shareUrl,
            setShareUrl
          }}
        >
          <VideoPlayerModalComponent />
        </VideoPlayerModalShareUrlContext.Provider>
      </VideoPlayerModalContext.Provider>
    </ResourceClickHandlerContext.Provider>
  )
}

export default VideoPlayerModal
