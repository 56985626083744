import * as React from 'react'
import { FunctionComponent } from 'react'
import Carousel from '../carousel'
import ResourceCard from '../resource-card'
import { Resource } from '@builder/resources'
interface ResourceListProps {
  title?: string
  resources: Resource[]
  isFeatured?: boolean
  handleSwitchVideo?: any
}

const ResourceList: FunctionComponent<ResourceListProps> = (props) => {
  const { title, resources, isFeatured = false } = props

  const responsive = {
    xl: {
      breakpoint: { max: 9000, min: 1440 },
      items: isFeatured ? 3 : 4,
      partialVisibilityGutter: isFeatured ? 60 : 0
    },
    lg: {
      breakpoint: { max: 1440, min: 1024 },
      items: isFeatured ? 2 : 3,
      partialVisibilityGutter: isFeatured ? 50 : 0
    },
    md: {
      breakpoint: { max: 1024, min: 464 },
      items: isFeatured ? 1 : 2,
      partialVisibilityGutter: isFeatured ? 80 : 0
    },
    sm: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 0
    }
  }

  return (
    <div className='relative -mx-2'>
      {title ? (
        <h4 className='mt-12 sm:mt-0 mb-3 text-2xl pl-2'>{title}</h4>
      ) : null}
      {resources?.length > 0 ? (
        <Carousel
          arrows={false}
          renderButtonGroupOutside={true}
          responsive={responsive}
          partialVisible={isFeatured}
          slides={resources}
          slideComponent={ResourceCard}
        />
      ) : null}
    </div>
  )
}

export default ResourceList
