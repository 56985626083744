import * as React from 'react'
import { FunctionComponent } from 'react'
import { AiFillEyeInvisible as Icon } from 'react-icons/ai'
import { IoCloseSharp as CloseIcon } from 'react-icons/io5'

const SensitiveContentWarning: FunctionComponent<{ hide: () => void }> = ({
  hide
}) => {
  return (
    <div className='relative px-6 pb-8 pt-6 md:px-10 md:pb-10 md:pt-10 rounded-lg bg-alpha-oatmeal-80 text-alpha-oatmeal-20 flex flex-col md:flex-row gap-4 md:gap-6 items-start md:items-center'>
      <div
        className='cursor-pointer absolute z-10 right-0 top-0 md:right-1 md:top-1 p-2 text-alpha-oatmeal-30'
        onClick={hide}
      >
        <CloseIcon className='text-3xl' />
      </div>
      <div className='w-auto md:w-40 flex-none flex md:flex-col justify-center items-center gap-3 md:gap-2'>
        <div className='flex-none rounded-full bg-alpha-oatmeal-60 w-8 sm:w-14 md:w-20 aspect-square flex justify-center items-center'>
          <Icon className='size-5 sm:size-8 md:size-10' />
        </div>
        <h4 className='text-alpha-oatmeal-30 text-base md:text-lg text-left md:text-center my-0'>
          {$localize`:alpha.label|Sensitive Content Warning - Heading@@sensitiveContentWarningHeading:Sensitive Content Warning`}
        </h4>
      </div>
      <div className='font-body text-sm'>
        <p className='m-0'>
          {$localize`:alpha.label|Sensitive Content Warning - Paragraph 1@@sensitiveContentWarningParagraph1:The Alpha Youth Series features real-life stories. Some of these stories discuss sensitive topics, such as mental health issues including anxiety, depression, and suicide. These themes may be unsettling for some viewers. If a group host or guest is struggling with these issues, please encourage them to consider reaching out for support. This may include but is not limited to:`}
        </p>
        <ul className='list-outside pl-6'>
          <li>{$localize`:alpha.label|Sensitive Content Warning - List Item 1@@sensitiveContentWarningListItem1:Contacting a parent or guardian, a teacher, church leader, or other responsible adult safeguarding staff member`}</li>
          <li>{$localize`:alpha.label|Sensitive Content Warning - List Item 2@@sensitiveContentWarningListItem2:Seeking medical support from a doctor, physician, or mental health expert`}</li>
          <li>{$localize`:alpha.label|Sensitive Content Warning - List Item 3@@sensitiveContentWarningListItem3:Calling a mental health or suicide prevention hotline in your country`}</li>
        </ul>
        <p className='m-0'>
          {$localize`:alpha.label|Sensitive Content Warning - Paragraph 2@@sensitiveContentWarningParagraph2:We also encourage course admins to research and compile a list of appropriate local organisations and other related resources to more specifically support and signpost people on your Alpha.`}
        </p>
      </div>
    </div>
  )
}

export default SensitiveContentWarning
