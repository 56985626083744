import * as React from 'react'
import { FunctionComponent, PropsWithChildren } from 'react'

type ResourceCardOverlayProps = {
  isAlwaysOnTop?: boolean
  isVisible?: boolean
}

const ResourceCardOverlay: FunctionComponent<
  PropsWithChildren<ResourceCardOverlayProps>
> = ({ children, isAlwaysOnTop, isVisible }) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-900/70 to-gray-900/50 transition-opacity flex box-border opacity-0  font-body font-bold text-sm sm:text-base
        ${isAlwaysOnTop ? `items-center justify-center ${isVisible ? 'opacity-100' : 'opacity-0'}` : ' hover:opacity-100 items-center'}
        `}
    >
      {children}
    </div>
  )
}

export default ResourceCardOverlay
