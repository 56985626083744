import VideoPlayerModal from './video-player-modal'
import VideoPlayer from './video-player'
import Example from './example'
import ResourceCard from './resource-card'
import ResourceList from './resource-list'
import ProductCard from './product-card'
import SubtitlesPage from './subtitles-page'
import ErrorNotification from './error-notification'
import ErrorBoundary from './error-boundary'
import Notification from './notification'
import ComponentWrapper from './component-wrapper'
import DropdownDialog from './dropdown-dialog'
import LoadingSection from './loaderSection'

import EditAlphaTabs from './edit-alpha/edit-alpha-tabs'
import PromoteTab from './edit-alpha/promote-tab'
import SeriesMaterialsTab from './edit-alpha/series-materials-tab'
import SensitiveContentWarning from './sensitive-content-warning'

const components = {
  DropdownDialog,
  VideoPlayerModal,
  Example,
  VideoPlayer,
  ResourceCard,
  ProductCard,
  ResourceList,
  EditAlphaTabs,
  SubtitlesPage,
  SeriesMaterialsTab,
  PromoteTab,
  ErrorNotification,
  ErrorBoundary,
  Notification,
  ComponentWrapper,
  LoadingSection,
  SensitiveContentWarning
}

export {
  DropdownDialog,
  VideoPlayerModal,
  Example,
  VideoPlayer,
  ResourceCard,
  ProductCard,
  ResourceList,
  EditAlphaTabs,
  SubtitlesPage,
  SeriesMaterialsTab,
  PromoteTab,
  ErrorNotification,
  ErrorBoundary,
  Notification,
  ComponentWrapper,
  LoadingSection,
  SensitiveContentWarning
}

export default components
