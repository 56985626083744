import { Inject, Injectable } from '@angular/core'
import { createElement, FunctionComponent, ReactElement } from 'react'
import { createRoot, Root } from 'react-dom/client'
import hash from 'object-hash'
import ReactUI from '@builder/react-ui'
import { DOCUMENT } from '@angular/common'
import { Locale } from '../lang/locale'
import { IComponentWrapperContext } from '@builder/react-ui/context'
import { TrackingService, TrackingEventName } from '@builder/tracking'
import { VideoService } from '../media/video'

type ReactComponentName =
  | 'VideoPlayerModal'
  | 'VideoPlayer'
  | 'Example'
  | 'SubtitlesPage'
  | 'Tabs'
  | 'SeriesMaterialsTab'
  | 'ErrorBoundary'
  | 'ProductCard'
  | 'PromoteTab'
  | 'ProductCard'

@Injectable({
  providedIn: 'root'
})
export class ReactService {
  private domNodeCache: { [key: string]: HTMLElement } = {}
  constructor(
    @Inject(DOCUMENT) private document: Document,
    public locale: Locale,
    public trackingService: TrackingService,
    private videosService: VideoService
  ) {}
  render({
    reactComponentName,
    mountDomNodeId,
    props
  }: {
    reactComponentName: ReactComponentName
    mountDomNodeId?: string
    props: any | undefined
  }) {
    const component = ReactUI[reactComponentName] as FunctionComponent<
      ReactElement<any, string>
    >

    if (!component) {
      throw `There is no React component matching this name:
      ${reactComponentName}`
    }

    if (mountDomNodeId && this.document.getElementById(mountDomNodeId)) {
      this.domNodeCache[mountDomNodeId] = this.document.getElementById(
        mountDomNodeId
      ) as HTMLElement
    }
    const id = mountDomNodeId ?? hash({ reactComponentName, props })

    if (this.domNodeCache[id]) {
      document.getElementById(id).remove()
    }
    const domNode = this.document.createElement('div')
    domNode.id = id
    this.document.body.appendChild(domNode)
    this.domNodeCache[id] = domNode
    this.reactRender(createRoot(domNode), component, props)
  }

  reactRender(
    root: Root,
    component: FunctionComponent<ReactElement<any, string>>,
    props: any | undefined
  ) {
    const wrapper = ReactUI['ComponentWrapper']
    const contextValue: IComponentWrapperContext = {
      unmount: () => {
        root.unmount()
      },
      locale: this.locale.dateLocale,
      isRtl: this.locale.isRTL,
      localeTranslate: (id, defaultValue) =>
        this.locale.translate(id, defaultValue),
      track: (eventName, data) => this.trackingService.trigger(eventName, data),
      fetchDownloadLink: (id) => this.videosService.fetchDownloadLink(id),
      trackingEventName: TrackingEventName
    }
    try {
      root.render(
        createElement(wrapper, {
          component,
          componentProps: props,
          contextValue
        })
      )
    } catch (error) {
      console.log('react.service.ts', error)
    }
  }
}
