import * as React from 'react'
import { ReactNode } from 'react'

type LinkType = {
  linkUrl: string
  linkLabel: ReactNode
}

type StepProps = {
  stepNumber: number
  heading: ReactNode
  text?: ReactNode
  listItem?: ReactNode[]
  media_src?: string
  media_alt?: string
  links?: LinkType[]
}

const SubtitlesStep = (props: StepProps) => {
  const { stepNumber, heading, text, media_src, media_alt, listItem, links } =
    props

  return (
    <div className='flex flex-col'>
      <span className='font-bold text-alpha-red text-base'>
        {$localize`:common.words|Common Word Step@@commonWordsStep:Step`}
        {` ${stepNumber}`}
      </span>
      <h3 className='m-0 text-xl'>{heading}</h3>

      {listItem && listItem.length > 0 && (
        <ol className='list-decimal list-inside my-4'>
          {listItem.map((item, index) => (
            <li className='text-base' key={index}>
              {item}
            </li>
          ))}
        </ol>
      )}

      {text ? <p className='text-base'>{text}</p> : null}

      {links && links.length > 0 && (
        <div className='my-4 flex flex-col'>
          {links.map((link, index) => (
            <a
              key={index}
              className='underline text-mya-purple-accent mr-4 text-base'
              href={link.linkUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {link.linkLabel}
            </a>
          ))}
        </div>
      )}

      {media_src ? (
        <img className='rounded-md' src={media_src} alt={media_alt} />
      ) : null}
    </div>
  )
}

export default SubtitlesStep
