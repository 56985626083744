import * as React from 'react'
import { FunctionComponent, useEffect, useState } from 'react'
import * as Ariakit from '@ariakit/react'
import {
  Heading,
  HeadingLevel,
  Disclosure,
  DisclosureContent,
  Dialog,
  useDialogStore,
  useDisclosureStore
} from '@ariakit/react'

import { IoCloseSharp as CloseIcon } from 'react-icons/io5'

export interface ErrorWithDetailedMessage extends Error {
  detailedMessage: string
}

interface NotificationProps {
  error: ErrorWithDetailedMessage
  onClose: () => void
}

const Notification: FunctionComponent<NotificationProps> = ({
  error,
  onClose
}) => {
  const dialog = useDialogStore()
  const disclosure = useDisclosureStore()
  const { message, detailedMessage, stack } = error

  useEffect(() => {
    dialog.show()
  }, [])

  const closeViaOverlay = (event) => {
    if (event.button === 0) {
      close()
    }
  }

  const close = () => {
    if (onClose) {
      onClose()
    }
    dialog.hide()
  }

  return (
    <Dialog
      store={dialog}
      hideOnInteractOutside={false}
      backdrop={
        <div
          className='fixed inset-0 bg-black opacity-50'
          onMouseDown={closeViaOverlay}
        />
      }
      className='fixed z-50 inset-3 w-5/6 max-w-screen-lg overflow-y-auto  text-black shadow-xl inset-y-[5vh] mx-auto'
    >
      <div className='relative bg-white rounded-md shadow-lg py-6 px-4'>
        <HeadingLevel level={4}>
          {message ? <Heading className='heading'>{message}</Heading> : null}
          {detailedMessage ? (
            <p className='font-body'>{detailedMessage}</p>
          ) : null}
          <Disclosure
            store={disclosure}
            className='bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow'
          >
            {$localize`:common.message|SeeFullDetails@@commonMessageSeeFullDetails:See full details`}
          </Disclosure>
          <DisclosureContent store={disclosure} className='content'>
            {detailedMessage ? (
              <p className='font-body'>{detailedMessage}</p>
            ) : null}
            {stack ? <p className='font-body'>{stack}</p> : null}
          </DisclosureContent>
        </HeadingLevel>

        <div
          className='flex cursor-pointer absolute z-10 right-4 top-4 p-2 text-black rounded-md hover:bg-slate-100 bg-white'
          onClick={close}
        >
          <CloseIcon className='fill-black text-3xl' />
        </div>
      </div>
    </Dialog>
  )
}

export default Notification
