import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { VideoData } from '@builder/common/models/video-data'

export const BRIGHTCOVE = 'brightcove'
export const VIMEO = 'vimeo'
export const GOOGLE_DRIVE = 'google-drive'

export type VideoProviders =
  | typeof VIMEO
  | typeof GOOGLE_DRIVE
  | typeof BRIGHTCOVE

export interface IVideo {
  title: string
  description: string
  duration: number
  width: number
  height: number
  url: string
  uri: string
  user: {}
  playing: boolean
  paused: boolean
  videoProvider: VideoProviders
}

export class ABVideo implements IVideo {
  public title: string
  public description: string
  public duration = 0
  public width: number
  public height: number
  public url = ''
  public uri: string
  public user: {}
  public playing = false
  public paused = false
  public videoProvider: VideoProviders
}

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  constructor(private http: HttpClient) {}

  /**
   * Get the download links for a video
   */
  public getVideo(item: any) {
    return this.http
      .get<any>(
        'wp-json/wp/v2/blog/' +
          item.blog_id +
          '/post/' +
          item.id +
          '/downloads',
        { params: { key: 'videos' } }
      )
      .pipe(
        map((response: any) => {
          return new VideoData(response, {
            sourceName: $localize`:common.words|Common Words - Original@@commonWordsOriginal:Original`
          })
        })
      )
  }

  public fetchDownloadLink(videoId) {
    return this.http.get<any>('wp-json/wp/v2/video/' + videoId + '/download')
  }
}
