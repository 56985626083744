import * as React from 'react'
import { FunctionComponent, PropsWithChildren, useContext } from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import ErrorNotification from './error-notification'
import { ComponentWrapperContext } from './context'

const ErrorBoundary: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { unmount } = useContext(ComponentWrapperContext)
  const fallbackRender = ({ error, resetErrorBoundary }) => {
    return <ErrorNotification error={error} unmount={unmount} />
  }

  return (
    <ReactErrorBoundary fallbackRender={fallbackRender}>
      {children}
    </ReactErrorBoundary>
  )
}

export default ErrorBoundary
