import * as React from 'react'
import { useState } from 'react'
import SubtitlesStep from './subtitles-page-step/subtitles-page-step'

const SubtitlesPage = () => {
  const [activeTab, setActiveTab] = useState('Computer')

  const handleTabClick = (tab) => {
    setActiveTab(tab)
  }

  return (
    <div className='z-50 text-black top-0 absolute bg-white w-full flex justify-center h-auto'>
      <div className='max-w-[657px] gap-4 flex flex-col my-12 p-4'>
        <h1 className='text-5xl sm:w-[70%]'>
          {$localize`:subtitlePage.title|SubtitlePageTitle1@@subtitlePageTitle1:One Video, Multiple Subtitles.`}
        </h1>
        <p className='text-xl'>
          {$localize`:subtitlePage.intro|SubtitlePageIntro1@@subtitlePageIntro1:Are you running Alpha in a multilingual church? Looking for Alpha
          video subtitles in other languages? Struggling to manage multiple
          video files in different languages? We have a solution for you.`}
          <br />
          <br />
          {$localize`:subtitlePage.intro|SubtitlePageIntro2@@subtitlePageIntro2:Our new video feature now offers all available subtitles built
          directly into the video. This streamlined approach allows you to
          download a single file and choose from a wide range of language
          options to accommodate your diverse audience. For optimal performance,
          we suggest using the powerful VLC player. Let me show you how to set
          up in just four easy steps.`}
        </p>
        <h3 className='sm:w-[70%]'>
          {$localize`:subtitlePage.heading|SubtitlePageHeading1@@subtitlePageHeading1:Steps to Download Videos and Access Subtitles`}
        </h3>

        <div className='border-solid border border-gray-300 rounded-md p-4'>
          <div className='tabs flex gap-5 mb-16'>
            <button
              className={`text-base	font-bold bg-transparent border-0 border-b-4 ${
                activeTab === 'Computer'
                  ? 'border-mya-purple-accent'
                  : 'border-transparent'
              }`}
              onClick={() => handleTabClick('Computer')}
            >
              {$localize`:common.words|Common Word - Computer@@commonWordsComputer:Computer`}
            </button>
            <button
              className={`text-base	font-bold bg-transparent border-0 border-b-4 ${
                activeTab === 'Mobile'
                  ? 'border-mya-purple-accent'
                  : 'border-transparent'
              }`}
              onClick={() => handleTabClick('Mobile')}
            >
              {$localize`:common.words|Common Word Mobile@@commonWordsMobile:Mobile`}
            </button>
          </div>

          <div className='flex flex-col gap-8'>
            {activeTab === 'Computer' ? (
              <>
                <SubtitlesStep
                  stepNumber={1}
                  heading={$localize`:subtitlePage.heading|SubtitlePageHeadingDownloadVideo@@subtitlePageDownloadVideo:Download an Alpha video`}
                  text={$localize`:subtitlePage.text|SubtitlePageSelectVideo@@subtitlePageSelectVideoDownload:Select a video and click download`}
                  media_src='/images/Gifs/VLC-GIFS/Desktop/Step1-Desktop.gif'
                  media_alt='Step1-Desktop-Gif'
                />

                <SubtitlesStep
                  stepNumber={2}
                  heading={$localize`:subtitlePage.heading|SubtitlePageHeadingDownloadPlayer@@subtitlePageDownloadPlayer:Download VLC Player`}
                  text={$localize`:subtitlePage.text|SubtitlePageSelectVideo@@subtitlePageSelectVideo:You can use any video player of your choice, but opting for VLC player ensures that all language options are accessible to you.`}
                  links={[
                    {
                      linkUrl: 'https://www.videolan.org/vlc/',
                      linkLabel: $localize`:subtitlePage.link|SubtitlePageVLCPlayerLink@@subtitlePageVLCPlayerLink:Download VLC Player`
                    }
                  ]}
                  media_src='/images/Gifs/VLC-GIFS/Desktop/Step2-Desktop.gif'
                  media_alt='Step2-Desktop-Gif'
                />

                <SubtitlesStep
                  stepNumber={3}
                  heading={$localize`:subtitlePage.heading|SubtitlePageOpenPlayer@@subtitlePageOpenPlayer:Open the video in VLC`}
                  text={$localize`:subtitlePage.text|SubtitlePageLocateVideo@@subtitlePageLocateVideo:Locate the Alpha video you’ve just downloaded and open it with VLC player`}
                  media_src='/images/Gifs/VLC-GIFS/Desktop/Step3-Desktop.gif'
                  media_alt='Step3-Desktop-Gif'
                />

                <SubtitlesStep
                  stepNumber={4}
                  heading={$localize`:subtitlePage.heading|SubtitlePageChooseALanguage@@subtitlePageChooseALanguage:Choose a subtitle language`}
                  listItem={[
                    $localize`:subtitlePage.listItem|VLCPlayerMenu@@vlcPlayerMenu:While the video is playing, go to the VLC menu on the top of your screen`,
                    $localize`:subtitlePage.listItem|ClickOnSubtitles@@clickOnSubtitles:Click on 'Subtitles'`,
                    $localize`:subtitlePage.listItem|SelectTrack@@selectTrack:Select 'Subtitle Track'`,

                    $localize`:subtitlePage.listItem|ChooseLanguage@@chooseLanguage:Choose the language you need`
                  ]}
                  media_src='/images/Gifs/VLC-GIFS/Desktop/Step4-Desktop.gif'
                  media_alt='Step4-Desktop-Gif'
                />
              </>
            ) : (
              <>
                <div className='flex flex-col gap-8'>
                  <SubtitlesStep
                    stepNumber={1}
                    heading={$localize`:subtitlePage.heading|SubtitlePageHeadingDownloadVideo@@subtitlePageDownloadVideo:Download an Alpha video`}
                    text={$localize`:subtitlePage.text|SubtitlePageSelectVideo@@subtitlePageSelectVideoDownload:Select a video and click download`}
                    media_src='/images/Gifs/VLC-GIFS/Mobile/Step1-Mobile.gif'
                    media_alt='Step1-Mobile-Gif'
                  />

                  <SubtitlesStep
                    stepNumber={2}
                    heading={$localize`:subtitlePage.heading|SubtitlePageHeadingDownloadPlayerMobile@@subtitlePageDownloadPlayerMobile:Download VLC Player App on your mobile device`}
                    text={$localize`:subtitlePage.text|SubtitlePageSelectVideo@@subtitlePageSelectVideo:You can use any video player of your choice, but opting for VLC player ensures that all language options are accessible to you.`}
                    links={[
                      {
                        linkUrl:
                          'https://www.videolan.org/vlc/download-ios.html',
                        linkLabel: $localize`:subtitlePage.link|SubtitlePageVLCPlayerLinkIOS@@subtitlePageVLCPlayerLinkIOS:VLC media player for iOS and iPadOS`
                      },
                      {
                        linkUrl:
                          'https://www.videolan.org/vlc/download-android.html',
                        linkLabel: $localize`:subtitlePage.link|SubtitlePageVLCPlayerLinkAndroid@@subtitlePageVLCPlayerLinkAndroid:VLC for Android`
                      }
                    ]}
                    media_src='/images/Gifs/VLC-GIFS/Mobile/Step2-Mobile.png'
                    media_alt='Step2-Mobile-Png'
                  />

                  <SubtitlesStep
                    stepNumber={3}
                    heading={$localize`:subtitlePage.heading|SubtitlePageVLCApp@@subtitlePageVLCApp:Open video with VLC App`}
                    listItem={[
                      $localize`:subtitlePage.listItem|OpenVLCMobile@@openVLCMobile:Open in VLC App on your mobile device`,
                      $localize`:subtitlePage.listItem|TapNetwork@@tapNetwork:In the menu tab, tap on 'Network'`,
                      $localize`:subtitlePage.listItem|LocateVideoInFiles@@locateVideoInFiles:Locate the downloaded Alpha video in 'Local files' or 'Cloud Services'`,
                      $localize`:subtitlePage.listItem|ClickPlayVideo@@clickPlayVideo:Click on the Alpha video to play`
                    ]}
                    media_src='/images/Gifs/VLC-GIFS/Mobile/Step3-Mobile.gif'
                    media_alt='Step3-Mobile-Gif'
                  />

                  <SubtitlesStep
                    stepNumber={4}
                    heading={$localize`:subtitlePage.heading|SubtitlePageChooseTheLanguage@@subtitlePageChooseTheLanguage:Choose the subtitle language`}
                    listItem={[
                      $localize`:subtitlePage.listItem|LookForSpeechIcon@@lookForSpeechIcon:While playing the video, click the Language and Subtitle button in the lower left-hand corner. (Look for a 'speech bubble icon'.)`,
                      $localize`:subtitlePage.listItem|SelectDesiredLanguage@@selectDesiredLanguage:Select the desired language from the list.'`,
                      $localize`:subtitlePage.listItem|AutomaticSubtitles@@automaticSubtitless:Subtitles in the chosen language should automatically appear on the video that is playing.`
                    ]}
                    media_src='/images/Gifs/VLC-GIFS/Mobile/Step4-Mobile.gif'
                    media_alt='Step4-Mobile-Gif'
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubtitlesPage
