import * as React from 'react'
import { FunctionComponent } from 'react'
import {
  MdVisibilityOff as PrivateIcon,
  MdSunny as PublicIcon
} from 'react-icons/md'

export interface ProductCardPillProps {
  variant: 'private' | 'public' | 'role'
  role?: 'teamMember' | 'administrator'
}

const ProductCardPill: FunctionComponent<ProductCardPillProps> = ({
  variant,
  role
}) => {
  const commonClasses =
    'flex items-center gap-2 h-6 py-1 px-4 rounded-full whitespace-nowrap leading-none font-body text-sm '
  if (variant === 'private') {
    return (
      <div
        className={`${commonClasses} text-alpha-white bg-mya-link font-normal`}
      >
        <PrivateIcon />
        <span>
          {$localize`:course.type|Alpha Course - Private Course@@privateCourse:Private Course`}
        </span>
      </div>
    )
  }

  if (variant === 'public') {
    return (
      <div
        className={`${commonClasses} text-mya-link bg-alpha-oatmeal font-medium`}
      >
        <PublicIcon />
        <span>
          {$localize`:course.type|Alpha Course - Public Course@@publicCourse:Public Course`}
        </span>
      </div>
    )
  }

  if (variant === 'role') {
    return (
      <div
        className={`${commonClasses} text-mya-link bg-alpha-oatmeal font-medium`}
      >
        {role === 'teamMember' ? (
          <span>
            {$localize`:alpha.users|Alpha User Roles - Administrator@@userRoleLabel_author:Administrator`}
          </span>
        ) : null}
        {role === 'administrator' ? (
          <span>
            {$localize`:alpha.users|Alpha User Roles - Administrator@@userRoleLabel_author:Administrator`}
          </span>
        ) : null}
      </div>
    )
  }

  return null
}

export default ProductCardPill
