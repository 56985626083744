import * as React from 'react'
import { FunctionComponent } from 'react'

import { IoChevronDown as ChevronDownIcon } from 'react-icons/io5'
import DropdownDialog from '@builder/react-ui/dropdown-dialog'

type PromoteTabCategoryDropdownProps = {
  options: any[]
  setSelectedOption: (input: any) => void
  selectedOption: {
    name: string
    label: string
  }
}

const PromoteTabCategoryDropdown: FunctionComponent<
  PromoteTabCategoryDropdownProps
> = ({ options, setSelectedOption, selectedOption }) => {
  if (!options) {
    return null
  }

  const button: FunctionComponent<{ onClick: () => void }> = ({ onClick }) => (
    <div
      role='button'
      onClick={onClick}
      className='bg-white hover:bg-slate-100 group flex gap-4 max-w-full md:max-w-[640px] justify-between pl-5 md:pl-8 xl:pl-10 pr-3 py-3 text-left items-center border-2 border-alpha-oatmeal-10 border-solid rounded-full font-display font-bold text-base lg:text-lg xl:text-xl text-alpha-black cursor-pointer flex-1 w-full lg:w-fit text-ellipsis overflow-hidden box-border'
    >
      {selectedOption.label}
      <div className='bg-black group-hover:bg-alpha-red text-white rounded-full flex justify-center items-center w-6 h-6 md:w-8 md:h-8 xl:w-10 xl:h-10 transition-colors'>
        <ChevronDownIcon className='size-4 md:size-5 xl:size-6' />
      </div>
    </div>
  )

  return options && options.length > 1 ? (
    <DropdownDialog
      button={button}
      options={options}
      setOption={setSelectedOption}
    />
  ) : null
}

export { PromoteTabCategoryDropdown }
