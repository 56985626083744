import { createContext, Dispatch, SetStateAction } from 'react'
import { VideoProps } from '../video-player-modal/video-player-utils'
import { Observable } from 'rxjs'

interface IComponentWrapperContext {
  unmount(): void
  locale: string
  isRtl: boolean
  localeTranslate(id, defaultValue): void
  track(eventName, data): void
  fetchDownloadLink(id): Observable<any>
  trackingEventName: { [key: string]: string }
}

const ComponentWrapperContext = createContext<IComponentWrapperContext>(null)

const VideoPlayerModalContext = createContext<VideoProps | null>(null)
const VideoPlayerModalShareUrlContext = createContext<{
  shareUrl: string
  setShareUrl: Dispatch<SetStateAction<string>>
}>(null)

const ResourceClickHandlerContext = createContext<any>(null)

export {
  IComponentWrapperContext,
  ComponentWrapperContext,
  VideoPlayerModalContext,
  VideoPlayerModalShareUrlContext,
  ResourceClickHandlerContext
}
