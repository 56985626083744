import {
  AfterViewInit,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core'
import { createRoot, Root } from 'react-dom/client'
import hash from 'object-hash'
import ReactUI from '@react-ui'
import * as React from 'react'
import { CommonModule } from '@angular/common'
import { ReactService } from './react.service'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'react-wrapper',
  template: `
    <div *ngIf="!hasError" [id]="id" className="w-full"></div>
    <p *ngIf="hasError">
      There is no React component matching this name:
      {{ this.name }}
    </p>
  `,
  encapsulation: ViewEncapsulation.None
})
export class ReactWrapperComponent implements OnDestroy, AfterViewInit {
  // property binding
  @Input() name: string
  @Input() props?: { [key: string]: any }
  @Input() providers?: [any]
  @HostBinding('class.w-full')
  public id: string
  public hasError: boolean = false
  public component: React.FunctionComponent | undefined = undefined

  public root: Root

  constructor(public reactService: ReactService) {}

  ngOnInit() {
    this.id = hash(this.name)
    this.component = ReactUI[this.name]
    if (!this.component) {
      this.hasError = true
    }
  }

  ngAfterViewInit() {
    if (!this.hasError) {
      this.createRootAndRender()
    }
  }

  // Ensure ngOnChanges or lifecycle methods in ReactWrapperComponent handle props update correctly
  ngOnChanges(changes: SimpleChanges) {
    if (changes?.props) {
      this.render() // Re-render React component if props change
    }
  }

  ngOnDestroy() {
    if (!this.hasError) {
      this.root.unmount()
    }
  }

  private createRootAndRender() {
    this.root = createRoot(document.getElementById(this.id))
    this.render()
  }

  private render() {
    if (!this.root) {
      return
    }

    try {
      const component = ReactUI[this.name]
      this.reactService.reactRender(this.root, component, this.props)
    } catch (error) {
      console.error({ error })
    }
  }
}
