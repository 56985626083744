import * as React from 'react'
import { FunctionComponent } from 'react'
import { Loader } from '@alpha-international/alpha-ui'

const LoadingSection: FunctionComponent = () => {
  return (
    <div className='w-full flex justify-center align-center lg:py-80 md:py-60 sm:py-40 py-20'>
      <Loader />
    </div>
  )
}

export default LoadingSection
