import * as React from 'react'
import { GOOGLE_API_KEY } from '../constants'

const GoogleDriveVideoPlayer = ({ url }) => {
  if (!url) {
    // Return a fallback UI or null when videoId is missing
    return <div>Video URL is missing or invalid</div>
  }
  const videoId = new URL(url).searchParams.get('id')
  const videoSrc: string =
    'https://www.googleapis.com/drive/v3/files/' +
    videoId +
    '/?key=' +
    GOOGLE_API_KEY +
    '&alt=media'

  return (
    <>
      {videoSrc ? (
        <video
          controls
          controlsList='nodownload'
          src={videoSrc}
          className='w-full h-full'
        >
          Your browser does not support the video tag.
        </video>
      ) : (
        <iframe
          src={videoSrc}
          className='aspect-video'
          width='640'
          height='480'
          allow='autoplay'
          title='Google Drive Video Player'
        ></iframe>
      )}
    </>
  )
}

export default GoogleDriveVideoPlayer
