import * as React from 'react'
import { FunctionComponent } from 'react'

export interface ProductCardDetailProps {
  text: string
  icon: FunctionComponent
}

const ProductCardDetail: FunctionComponent<ProductCardDetailProps> = ({
  text,
  icon
}) => {
  const Icon = icon
  return (
    <div className='flex gap-2 text-alpha-oatmeal-70 text-base'>
      <div className='flex-none flex flex-col justify-start text-xl pt-1'>
        <Icon />
      </div>
      <span className='font-body text-lg sm:text-xl m-0 text-alpha-black'>
        {text}
      </span>
    </div>
  )
}

export default ProductCardDetail
