import * as React from 'react'
import { FunctionComponent } from 'react'
import BrightCoveVideoPlayer from './brightcove-video-player'
import VimeoVideoPlayer from './vimeo-video-player'
import GoogleDriveVideoPlayer from './google-drive-video-player'
export const BRIGHTCOVE = 'brightcove'
export const VIMEO = 'vimeo'
export const GOOGLE_DRIVE = 'google-drive'
export type VideoProviders =
  | typeof BRIGHTCOVE
  | typeof VIMEO
  | typeof GOOGLE_DRIVE
export interface VideoPlayerProps {
  provider: VideoProviders
  id?: string
  url?: string
  onPlay?: () => void
  onProgress?: (data: { secondsElapsed?: number; percentage?: number }) => void
  isPreviewView?: boolean
  autoPlay?: boolean
}

const VideoPlayer: FunctionComponent<VideoPlayerProps> = ({
  url,
  id,
  provider,
  isPreviewView,
  autoPlay
}) => {
  if (!provider) {
    return (
      <p>
        There is no video player type defined. The video URL may be invalid.
        <br />
        Video URL: {url}
      </p>
    )
  }

  let playerComponent
  if (provider === BRIGHTCOVE) {
    playerComponent = (
      <BrightCoveVideoPlayer
        id={id}
        isPreviewView={isPreviewView}
        autoPlay={autoPlay}
      />
    )
  }

  if (provider === VIMEO) {
    return (playerComponent = (
      <VimeoVideoPlayer
        url={url}
        onProgress={(progress) => {}}
        onPlay={() => {}}
      />
    ))
  }

  if (provider === GOOGLE_DRIVE) {
    playerComponent = <GoogleDriveVideoPlayer url={url} />
  }
  return (
    <div className='relative aspect-video'>
      {isPreviewView ? (
        <div
          className='absolute w-full h-full z-10 opacity-50 pointer-events-none	'
          style={{
            background: `no-repeat center/50% url(
              'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4yLjEsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCA1ODIuMSA1MjUuMyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTgyLjEgNTI1LjM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiNFRTMxMjQ7fQ0KPC9zdHlsZT4NCjxnIGlkPSJfeDMwXzFfMTJfIj4NCgk8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMTc4LjEsNDM2LjNjMCw0OS4yLTM5LjksODkuMS04OS4xLDg5LjFDMzkuOSw1MjUuMywwLDQ4NS40LDAsNDM2LjNjMC00OS4yLDM5LjktODkuMSw4OS4xLTg5LjENCgkJQzEzOC4yLDM0Ny4yLDE3OC4xLDM4Ny4xLDE3OC4xLDQzNi4zIi8+DQoJPHBhdGggY2xhc3M9InN0MCIgZD0iTTE5NC45LDc0LjdsMTEuNC0xMS40Yzg3LjQtODcuNCwyMTcuMS04NCwzMDkuOSw4LjdjODEuMyw4MS4zLDg4LjEsMjAxLDE2LjEsMjcyLjkNCgkJYy0yMS41LDIxLjUtNDkuNywzNy42LTgwLDQ1Yy0yOC45LDcuNC00Ny4xLDguMS0xMDYuOSw2LjFjLTY3LjktMi05Mi4xLDItMTE0LjMsMjAuMkwxMjcuMSwzMTJjMzMuNi0zNSw3OS4zLTQ3LjEsMTY4LjEtNDQuNA0KCQljNTkuOCwwLjcsODguNy02LjcsMTA4LjItMjYuMmMyNC4yLTI0LjIsMjUuNS00OS43LDIuNy03Mi42Yy0yOC45LTI4LjktNTYuNS0yNC4yLTk5LjUsMTcuNUwxOTQuOSw3NC43eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo='
            )`
          }}
        ></div>
      ) : null}
      {playerComponent}
    </div>
  )
}

export default VideoPlayer
